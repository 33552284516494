import styled from "styled-components";

export const TabAndTileCardsContainer = styled.div`
    display: flex;
    padding: 90px 50px 50px 50px;
    width:100%;
    overflow: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const TabAndTileCard = styled.a`
    text-decoration: none;
    flex: 0 0 329px;
    height: 231px;
    background: linear-gradient(120.13deg, ${(props)=>props.tilePrimaryColor} 3.17%, ${(props)=>props.tileSecondaryColor} 105.79%);
    margin-right: 35px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 21px;
    &:hover {
        transform: scale(1.1);
        transition-property: transform;
        transition-duration: .5s;
        transtition-timing-function: ease-in-out;
    }
`;

export const TileTitleFirstPart = styled.div`
    color: #FFFFFF;
    font-family: 'Weisshorn';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
`;

export const TileTitleSecondPart = styled.div`
    color: #FFFFFF;
    font-family: 'Weisshorn Rough';
    font-style: normal;
    font-weight: 700;
    font-size: 47px;
    line-height: 47px;
`;

export const TabAndTileVerticalCardsContainer = styled.div`
`;

export const TabAndTileVerticalCard = styled.a`
    display: flex;
    justify-content: space-between;
    margin: 24px 148px;
    height: 201px;
    background: linear-gradient(90deg, #2B2929 80.67%, rgba(0, 0, 0, 0) 92.6%);    
    border: 2px solid #FFFFFF;
    border-radius: 16px;
    padding-left: 41px;
    text-decoration: none;
    &:hover {
        transform: scale(1.05);
        transition-property: transform;
        transition-duration: .5s;
        transtition-timing-function: ease-in-out;
    }
`;

export const TabAndTileVerticalDetails = styled.div`
    width: 550px;
    color: #FFFFFF;
    font-style: normal;
    padding-top: 19px;
`;

export const TileTitleVerticalFirstPart = styled.div`
    font-family: 'Rampage Monoline';
    font-weight: 400;
    font-size: 34px;
    height: 34px;
`;

export const TileTitleVerticalSecondPart = styled.div`
    font-family: 'Weisshorn Rough';
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    margin-top: 7px;
`;

export const TileVerticalDescription = styled.div`
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 17px;
`;

export const TabAndTileVerticalImg = styled.div`
    width: 243px;
    background-image: url(${(props)=>props.imgURL});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 0px 14px 14px 0px;
`;