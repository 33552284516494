import React, { useState } from 'react';
import {getCourseCardDetails, getSessionDetails, getCourseURLs} from '../../helpers/courseCardHelper';
import { getTeacherCardDetails } from 'src/consumer/helpers/teacherCardHelper';
import { useFetch } from '../../services/index';
import Player from '../Player';

export default function CoursePlayer({selectedCard, showPlayer, toggleDrawer, setShowPlayer}) {
  const [ongoingSession, setOngoingSession] = useState(0);
  const {courseTitle, courseId, courseType, courseLongDescription} = getCourseCardDetails(selectedCard);
  const {selectedSessionId, sessionsList} = getSessionDetails(selectedCard, ongoingSession);
  const {teacherTitle, teacherImage} = getTeacherCardDetails(selectedCard);
  const { loading, data, error } = useFetch(`/secure/courses/${courseId}/sessions/${selectedSessionId}`);
  //const signedURL = getCourseURLs(data);
  const signedURL = [data?.preparatory, data?.preInstructions, data?.meditate, data?.postInstructions];
  const token = sessionStorage.getItem('token');
  return (
          <Player 
            signedURL={signedURL}
            teacherImage={teacherImage}
            teacherTitle={teacherTitle}
            courseTitle={courseTitle}
            courseLongDescription={courseLongDescription}
            loading={loading}
            courseType={courseType}
            showPlayer={showPlayer}
            toggleDrawer={toggleDrawer}
            ongoingSession={ongoingSession}
            setOngoingSession={setOngoingSession}
            sessionsList={sessionsList}
            courseId={courseId}
            sessionId={selectedSessionId}
          />
          // <UpcomingSessions>

          // </UpcomingSessions>
  );
}
