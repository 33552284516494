import React, { Suspense } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import { AuthContextProvider } from './context/AuthContext'
import ProtectedRoute from './views/pages/login/ProtectedRoute'
import 'react-toastify/dist/ReactToastify.css'
import AuthorityCheck from './views/pages/login/authorityCheck'
import DefaultLayout from './layout/DefaultLayout';
import TilesPage from './consumer/components/TilesPage';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const ConsumerHomepage = React.lazy(() => import('./consumer/components/ConsumerHomepage'))
const GiftCard = React.lazy(() => import('./GiftCard/components/GiftCardHomepage'))
const GiftCardRedeem = React.lazy(() => import('./GiftCard/components/GiftCardRedeem'))
const ConsumerProfilePage = React.lazy(() => import('./consumer/components/ConsumerProfilePage'))
// const ProfileLayout = React.lazy(() => import('./layout/ProfileLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/signIn'))
const MobileSignIn = React.lazy(() => import('./consumer/components/SignIn/MobileSignIn'))
const Demo = React.lazy(() => import('./views/pages/Demo'))
const ForgotPassword = React.lazy(() => import('./views/pages/login/forgotPassword'))
const ChangePassword = React.lazy(() => import('./views/pages/login/changePassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  sessionStorage.setItem('languageCode', sessionStorage.getItem('languageCode') || (navigator?.language || navigator?.userLanguage).split('-')[0]);
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <AuthContextProvider>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/mobileSignIn" name="Mobile SignIn Page" element={<MobileSignIn />} />
            <Route exact path="/getDemo" name="Demo Page" element={<Demo />} />
            <Route
              exact
              path="/account/recover/:token"
              name="Change Password"
              token="qwert"
              element={<ChangePassword />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route exact path="/gift-card" name="Gift Card" element={<GiftCard />} />
            {/* <Route exact path="/gift-card/redeem" name="Gift Card Redeen" element={
              <ProtectedRoute><GiftCardRedeem /></ProtectedRoute>} /> */}
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/403" name="Page 403" element={<AuthorityCheck />} />
            <Route exact path="/error" name="Page 500" element={<Page500 />} />
            <Route
              path="/*"
              name="Consumer Home"
              element={
                <ProtectedRoute>
                  <ConsumerHomepage />
                </ProtectedRoute>
              }
            >
              <Route element={<TilesPage/>} path=":tileId/:policyId"/>
              <Route element={<TilesPage/>} path=":tileId"/>
              <Route element={<TilesPage/>} path=""/>
              <Route element={<ConsumerProfilePage />} path="profile"/>
              <Route path="redeem" name="Gift Card Redeen" element={
              <GiftCardRedeem />} />
            </Route>
            <Route
              path="*"
              name="Home"
              element={
                <ProtectedRoute>
                  <DefaultLayout />
                </ProtectedRoute>
              }
            />
            {/* <Route path="/userProfile/*" name="Profile" element={<ProfileLayout />} /> */}
          </Routes>
        </AuthContextProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
