import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

export const StyledDrawer = styled(Drawer)`
    display: ${(props)=>props.showPIP ? "none" : "block"};
    .MuiPaper-root{
        min-height: 100vh;
        backdrop-filter: blur(18px);
        background-color: rgba(0, 0, 0, 0.5);
    }
`;

export const StyledPlayerContainer = styled.div`
    width: 100%; 
`;

export const StyledPlayerHeader = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
    background: rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
`;

export const MinimizeButton = styled.img`
    z-index: 10000;
    height: 61px;
    &:hover {
        cursor: pointer;
    }
    margin-left: 18px;
`;

export const OpenPlaylistButton = styled.img`
    z-index: 10000;
    height: 61px;
    &:hover {
        cursor: pointer;
    }
    margin-left: auto;
`;

export const StyledHeaderButton = styled.img`
    z-index: 10000;
    height: 61px;
    &:hover {
        cursor: pointer;
    }
`;

export const PlayListContainerPopup = styled(Popover)`
    .MuiPaper-root{
        height: 400px;
        background: rgba(0,0,0,0.5);
        backdrop-filter: blur(8px);
    } 
`;

export const CoursePlayListHeader = styled.div`
    width: 100% !important;
    padding-top: 18px;
    display: flex;
    justify-content: center;
    width: 94px;
    height: 46px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 46px;
    /* identical to box height */

    letter-spacing: 0.08em;

    color: #FFFFFF;
`

export const StyledPlayer = styled.div`
    height: 100vh;
`;

export const StyledDetailsTitle = styled.div`
    color: #FFFFFF;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: 0.06em;
    padding-top: 57px;
`;

export const StyledDetailsDescription = styled.div`
    color: #F9F9F9;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 27px;
    width: 50%;
`;

export const StyledDetailedContent = styled.div`
    padding-top: 30px;
    display: flex;
`;

export const StyledTeacherImage = styled.div`
    height: 110px;
    width: 110px;
    background-image: url(${(props)=>props.imgURL});
    border-radius: 50%;
    background-size: cover;
`;

export const StyledTeacherDetails = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: right;
    align-items: center;
`;

export const StyledTeacherName = styled.div`
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 16px;
    color: #FFFFFF;
    padding-left: 15px;
`;
