import { useEffect, useState } from 'react';
import axios from 'axios';

export const apiURL = 'https://live.aumhum.xyz/api';
export const mediaURL = 'https://media.aumhum.xyz/images/prod/';
export const useFetch = (path, customParams = '') => {
const languageCode = sessionStorage.getItem('languageCode') 
  const params = `languageCode=${languageCode}&platform=ios&countryCode=in`;
  const [state, setState] = useState({
    loading: true,
    data: null,
    error: null,
  })
  const token = sessionStorage.getItem('token')
  useEffect( () => {
    setState({loading: true, data: null, error: null});
    axios
      .get(`${apiURL}${path}?${params}${customParams}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setState({ data: res.data, loading: false, error: null })
      })
      .catch((error) => {
        setState({ data: null, loading: false, error: error })
      })},
    [path, customParams, params, token]
  )
  return state;
}

export async function verifyLogin(accessToken) {
  const countryCode = 'in'
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `${apiURL}/user/firebase/login?languageCode=${languageCode}
        &countryCode=${countryCode}&accessToken=${accessToken}`
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
