import React from 'react';
import TabAndTileCards from '../TabAndTileCards';
import CourseCards from '../CourseCards';
import CategoryCards from '../CategoryCards';
import TeacherCards from '../TeacherCards';
import {CardListContainer} from './CardListStyle';

const CardList = (props) => {
    const {cardsType} = props;
    return (
        <CardListContainer>
            {cardsType === 'tabAndTile' && <TabAndTileCards {...props}/>}
            {cardsType === 'course' && <CourseCards {...props}/>}
            {cardsType === 'tag' && <CategoryCards {...props}/>}
            {cardsType === 'teacher' && <TeacherCards {...props}/>}
        </CardListContainer>
    )
}

export default CardList;
