import styled from "styled-components";

export const CourseCardsContainer = styled.div`
    display: flex;
    padding: 90px 50px 50px 50px;
    width:100%;
    overflow: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const CategoryCard = styled.div`
    flex: 0 0 458px;
    display: flex;
    height: 245px;
    margin-right: 66px;
    border-radius: 11px;
    position: relative;
    background-color: ${(props)=>props.bgColor};
    box-shadow: 25px 25px 30px rgba(0, 0, 0, 0.75);
    overflow: hidden;
    border-color: #FFFFFF;
    border-width: thin;
`;

export const CategoryImage = styled.div`
    width: 50%;
    background-image: url(${(props)=>props.imgURL});
    background-repeat: no-repeat;
    background-size: 100% 100%;
`;

export const CategoryTitle = styled.div`
    width: 50%;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    color: #FFFFFF;
    padding: 18px 20px 0px 20px;
`;