import axios from "axios";

export async function putSessionProgress(url, data){
    const token = sessionStorage.getItem('token')
    try {
        // const os = `${platform.os.family}`.toLowerCase()
        const res = await axios.post(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              'ef-user-country-code': 'in',
              'ef-app-language': 'en',
              'ef-app-version':'4.0.0',
              'ef-app-platform': 'ios',
              'ef-app-package': 'com.aumhum.web',
              'ef-app-installation-id': 'abcd123456789012', 
            },
          });
        return res
      } catch (err) {
        console.log(err)
        return err
      }
}