export const mediaURL = 'https://media.aumhum.xyz/images/prod/';
export const defaultBgImgId = 'MEDIA-6c1f023108f44331a3b13a68015ba4d2';
export const defaultColor = "#5C4544";
export const supportedCategories = ['tabAndTile', 'course', 'tag', 'teacher'];
export const HERO_CARD = 'heroCard';

export const GENDER_LIST = [
    {
        id: "male",
        displayValue: "He",
        icon: "male_gender_icon",
        selectedIcon: "male_gender_selected_icon"
    },
    {
        id: "female",
        displayValue: "She",
        icon: "female_gender_icon",
        selectedIcon: "female_gender_selected_icon"
    },
    {
        id: "nonBinary",
        displayValue: "Non-binary",
        icon: "others_gender_icon",
        selectedIcon: "others_gender_selected_icon"
    }
]