import styled from 'styled-components';

export const StyledTilesPageContainer = styled.div`
    margin-top: -130px;
    background-image:  ${(props)=> props.hasHeroCard ? 'none' :  `url(${props.imgURL})`};
    background-repeat: no-repeat;
    background-size: 100% 1350px;
`;

export const StyledTilesPageHeader = styled.div`
    height: 75vh;
    max-height: 1350px;
    min-height: 700px;
    align-items: center;
    justify-content: center;
    padding-top: 230px;
    display: flex;
`;

export const StyledTilesPageTitle = styled.div`
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 68px;
    line-height: 82px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledTitleSecondPart = styled.div`
    font-family: 'Bebas Neue';
    font-size: 297px;
    line-height: 235px;
`;

export const StyledTilesList = styled.div`
    background-image: linear-gradient(to bottom, rgba(30,31,35,0), rgba(30,31,35,1));
    background-size: 100% 658px;
    background-repeat: no-repeat;
`;

export const TilesPageTrialBanner = styled.div`
    width: 268px;
    height: 54px;
    background: linear-gradient(92.66deg, #6777E1 20.15%, #7351AA 108.2%);
    backdrop-filter: blur(4.5px);

    border-radius: 44px;
    align-items: center;
    justify-content: center;
    display: flex;

    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    color: #FFFFFF;

    &:hover {
        cursor: pointer;
    }
`;

export const TilesPageTrialBannerContainer = styled.div`
    padding-top: 280px;
    justify-content: center;
    display: flex;
`;