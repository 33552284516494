import styled from 'styled-components';

export const StyledTileContainer = styled.div`
    padding-top: 100px;
`;

export const StyledTileHeader = styled.div`
    overflow: hidden;
    padding-left: 50px;
`;

export const StyledTileTitle = styled.div`
    height: 60px;
    background: rgba(0, 0, 0, 0.36);
    backdrop-filter: blur(26px);
    border-radius: 47px;
    width: fit-content;
    min-width: 66px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 42px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
`;

export const StyledTileMore = styled.div`
    margin-right: 100px;
    height: 60px;
    background: rgba(0, 0, 0, 0.36);
    backdrop-filter: blur(26px);
    border-radius: 47px;
    width: fit-content;
    min-width: 60px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 30px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
`;