export const getCurrentTabOrder = (data) => {
  const tabOrderList = data?.tabOrders || [];
  let currentTabOrder = data?.tabOrders[0];
  Array.isArray(tabOrderList) && tabOrderList.forEach(tabOrder => {
    const date = new Date();
    const currentTime = `${data.getHours}:${data.getMinutes}:${data.getSeconds}`
    if(tabOrder.startTime <= tabOrder.endTime) {
      if(currentTime > tabOrder.startTime && currentTime < tabOrder.endTime)
        currentTabOrder =  tabOrder;
    } else {
      if(currentTime < tabOrder.startTime || currentTime > tabOrder.endTime)
        currentTabOrder =  tabOrder;
    }
  })
  return currentTabOrder;
}

export const getTabOrder = (data) => {
  const currentTabOrder = getCurrentTabOrder(data);
  return currentTabOrder || {};
}

export const getTabsListMap = (data) => {
  const tabTilesList = data?.tabTiles || [];
  const tilesIdToListMap = new Map();
  Array.isArray(tabTilesList) && tabTilesList.forEach(tabTile => {
    tilesIdToListMap.set(tabTile?.id, tabTile);
  })
  return tilesIdToListMap;
}

export const rightSideTabs = ['my_library'];