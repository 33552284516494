import { IconButton } from "@mui/material";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled as muiStyled }  from '@mui/material/styles';

export const StyledHeroCardContainer = styled.div`
    width: 100%;
    height: 75vh;
    max-height: 1350px;
    min-height:1000px;
    min-width: 1000px;
    margin-top: 0px;
`;

export const StyledHeroCard = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url(${(props)=>props.imgURL});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledHeroCardTransition = styled.div`
    margin-top: -250px;
    height: 255px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(30,31,35,0), rgba(30,31,35,1));
    background-size: 100%;
    background-repeat: no-repeat;
`;

export const StyledHeroCardContentContainer = styled.div`
    padding-top: 150px;
    margin-left: 151px;
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
`;
export const StyledHeroCardDesc = styled.div`
    margin-top: 21px;
`;
export const StyledHeroCardTitle = styled.div`
    font-size: 64px;
    line-height: 77px;
`;

export const StyledCoursePlay = styled(IconButton)`
    .MuiSvgIcon-root{
        width: 128px;
        height: 128px;
        color: #FFFFFF;
    }
`;

export const StyledCarouselPrevIcon = muiStyled(ArrowBackIosNewIcon)`
        width: 60px;
        height: 90px;
        color: #FFFFFF;
`;

export const StyledCarouselNextIcon = muiStyled(ArrowForwardIosIcon)`
        width: 60px;
        height: 90px;
        color: #FFFFFF;
`;

export const StyledPlayButtonContainer = styled.button`
    display: flex;
    align-items: center;
    margin-top: 73px;
    background: none;
    border: none;
    color: #FFFFFF;
`;

export const StyledCarousel = styled(Carousel)`
    .carousel-control-prev {
        margin-top: 124px;
        width: 5%;
        .carousel-control-prev-icon{
            width: 48px;
            height: 48px;
        }
    }
    .carousel-control-next {
        margin-top: 124px;
        width: 5%;
        .carousel-control-next-icon{
            width: 48px;
            height: 48px;
        }
    }
    .carousel-indicators{
        bottom: 10%;
        button {
            width: 16px;
            height: 16px;
            background-color: darkgray;
            border-radius: 50%;
            border: none;
            margin-right: 14px;
        }
        .active {
            background-color: #FFFFFF;        
        }
    }

`;