import styled from 'styled-components';
import { Slider } from '@material-ui/core';

export const StyledControlBar = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: rgba(0,0,0,0);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

`;
export const StyledPlayControls = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    box-shadow: 0px -27px 60px rgba(0, 0, 0, 0.51);
    backdrop-filter: blur(8px);
    border-radius: 16px;
    width: 450px;
    height: 93px;
    gap: 80px;
`;

export const StyledPlayBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
`
export const StyledDetailsIcon = styled.div`
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 4px 6px 9px rgba(0, 0, 0, 0.41);
    backdrop-filter: blur(6.5px);
    border-radius: 50px;
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`
export const StyledSliderContainer = styled.div`
    width: 70%;
    color: white;
    display: flex;
    gap: 17px;
    margin-top: 34px;
    margin-bottom: 52px;
    align-items: center;
`;
export const StyledPlayPauseButton = styled.img`
    &:hover {
        cursor: pointer;
    }
`;

export const PrettoSlider = styled(Slider)`
    height: 8px !important;
    & .MuiSlider-track {
      border: 'none';
      height: 10px;
      background-color: #706BCA;
    }
    & .MuiSlider-rail {
        height: 8px;
        background: #9A9A9A;
    }
    & .MuiSlider-thumb {
      height: 18px;
      width: 18px;
      background: #FFFFFF;
    },
    & .MuiSlider-mark {
        width: 5px;
        height: 22px;
        border-radius: 7px;
        margin-top: -6px;
    }
`;