import React from "react";
import { AudioPipContainer, StyledSliderContainer, PrettoSlider, StyledPlayControls, StyledPlayPauseButton, StyledForwardButton, StyledHeaderButton, StyledSessionIcon, StyledSessionTitle, StyledSessionItemDetail, StyledSessionSubTitle } from "./AudioPipStyle";
import playIcon from 'src/assets/PlayIcon.png'
import rewindIcon from 'src/assets/RewindIcon.png'
import forwardIcon from 'src/assets/ForwardIcon.png'
import pauseIcon from 'src/assets/PauseIcon.png'
import closeDrawerIcon from 'src/assets/close_drawer.png';
import {mediaURL} from '../../constants';


export const AudioPip = (props) => {

    const {handleDisablePIP, handleFastForward, handlePlayPause, handleRewind, handleSeek, play, handleSeekCommitted, handlePlayerClose, ongoingSession, sessionsList, teacherTitle} = props;
    return (<AudioPipContainer>
                <StyledSessionItemDetail onClick={handleDisablePIP}>
                    <StyledSessionIcon imgURL={mediaURL + sessionsList[ongoingSession].coverIcon}></StyledSessionIcon>
                    <div>
                        <StyledSessionTitle>{sessionsList[ongoingSession]?.title}</StyledSessionTitle>
                        <StyledSessionSubTitle>{teacherTitle}</StyledSessionSubTitle>
                    </div>
                </StyledSessionItemDetail>
                <StyledPlayControls>
                    <StyledForwardButton onClick={handleRewind} src={rewindIcon} />
                    {play.playing && <StyledPlayPauseButton onClick={handlePlayPause} src={pauseIcon} />}
                    {!(play.playing) && <StyledPlayPauseButton onClick={handlePlayPause} src={playIcon} />}
                    <StyledForwardButton onClick={handleFastForward} src={forwardIcon} />
                </StyledPlayControls>
                <StyledSliderContainer>
                    <PrettoSlider value={(play.played)*100} onChangeCommitted={handleSeekCommitted} onChange={handleSeek}/>
                </StyledSliderContainer>
                <StyledHeaderButton onClick={handlePlayerClose} src={closeDrawerIcon}/>
        </AudioPipContainer>);
}