import styled from "styled-components";

export const LeavingEarlyContainer = styled.div`
    display: flex;
    position: absolute;
    top: 0px;
    height: 100vh;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(98, 98, 98, 0.37);
    backdrop-filter: blur(11.5px);
    z-index: 10001;

`;

export const LeavingEarlyBox = styled.div`
    width: 825px;
    height: 529px;
    background: rgba(0, 0, 0, 0.66);
    backdrop-filter: blur(12.5px);    
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 73px;
    padding: 37px;
`;

export const LeavingEarlyHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
`;

export const LeavingEarlyTitle = styled.div`
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 220px;
    align-self: flex-end;
`;

export const LeavingEarlySubTitle = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
`;

export const LeavingEarlyActionContainer = styled.div`
    display: flex;
    gap: 25px;
    flex-direction: column;
`;

export const LeavingEarlyActions = styled.div`
    display: flex;
    gap: 46px;
    margin-left: 10px;
`;

export const ContinueButtonContainer = styled.button`
    background: linear-gradient(180deg, #05B9FF -55%, rgba(77, 98, 255, 0) 100%);
    border: 1px solid #FFFFFF;
    border-radius: 11px;
    box-sizing: border-box;
    width: 330px;
    height: 49px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
`;

export const ExitButtonContainer = styled.button`
    border: 1px solid #FFFFFF;
    border-radius: 11px;
    width: 330px;
    height: 49px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
`;

export const CloseIcon = styled.img`
    z-index: 10000;
    height: 38px;
    width: 38px;
    &:hover {
        cursor: pointer;
    } 
`;