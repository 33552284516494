import React from "react";
import { PrettoSlider, StyledControlBar, StyledDetailsIcon, StyledPlayBar, StyledPlayControls, StyledPlayPauseButton, StyledSliderContainer } from "./PlayerControlsStyle";
import playIcon from 'src/assets/PlayIcon.png'
import rewindIcon from 'src/assets/RewindIcon.png'
import forwardIcon from 'src/assets/ForwardIcon.png'
import pauseIcon from 'src/assets/PauseIcon.png'
import detailsIcon from 'src/assets/DetailsIcon.png'

export default function PlayerControls(props) {

    const format = (seconds) => {
        if(isNaN(seconds))
          return '00:00';
        const date = new Date(seconds*1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2,"0");
        if(hh)
          return `${hh}:${mm.toString().padStart(2,"0")}:${ss}`;
        return `${mm}:${ss}`;
    
    }

    const {handleFastForward, handlePlayPause, handleRewind, handleSeek, currentTime, duration, play, handleSeekCommitted, setIsCourseDetailOpen, stepsArray, currentStep} = props;
    const marks = stepsArray.map(step => {
        return {
            value: step.mark
        }
    })
    return (
        <StyledControlBar>
            <StyledPlayBar>
                <StyledDetailsIcon onClick={()=>setIsCourseDetailOpen(true)}>
                    <img src={detailsIcon}/>
                </StyledDetailsIcon>
                <StyledPlayControls>
                    <StyledPlayPauseButton onClick={handleRewind} src={rewindIcon} />
                    {play.playing && <StyledPlayPauseButton onClick={handlePlayPause} src={pauseIcon} />}
                    {!(play.playing) && <StyledPlayPauseButton onClick={handlePlayPause} src={playIcon} />}
                    <StyledPlayPauseButton onClick={handleFastForward} src={forwardIcon} />
                </StyledPlayControls>
            </StyledPlayBar>
            <StyledSliderContainer>
                <span>{format(currentTime)}</span>
                <PrettoSlider value={(currentStep + play.played) * parseFloat(100/stepsArray.length)} onChangeCommitted={handleSeekCommitted} marks={marks} onChange={handleSeek}/>
                <span>{format(duration)}</span>
            </StyledSliderContainer>
      </StyledControlBar>
    )
}
