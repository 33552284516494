import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

export const StyledDrawer = styled(Drawer)`
    .MuiPaper-root{
        height: 90vh;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0px -27px 60px rgba(0, 0, 0, 0.51);
        backdrop-filter: blur(19px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 6px 6px 0px 0px;
        padding: 55px 90px 20px 55px;
    }
`;

export const StyledDetailContainer = styled.div`
    width: 100% 
`;

export const StyledBackButton = styled(IconButton)`
    height: 40px;
    z-index: 1000;
    
    color: #FFFFFF !important;
`;

export const StyledDetailContent = styled.div`
    display: flex;    
`;

export const StyledCourseDetail = styled.div`
    padding-top: 55px;
    padding-left: 132px;
    width: 60%;
    padding-right: 120px;
`;

export const StyledCourseTitle = styled.div`
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
`;

export const StyledCourseTeacher = styled.div`
    padding-top: 11px;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #D9D9D9;
`;

export const StyledCourseDesc = styled.div`
padding-top: 40px;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 27px;
    color: #F9F9F9;
`;

export const StyledCoursePlaylist = styled.div``;
export const StyledPlaylistTitle = styled.div`
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 37px;

    color: #FFFFFF;
`;
export const StyledSessionListContainer = styled.div`
    padding-top: 18px;
`;
export const StyledSessionListItem = styled.div`
    display: flex;
    width: 453px;
    height: 106px;
    padding: 18px;
    margin-top: 10px;
    background: ${(props) => props.showBackground ? '#232325' : 'none'};
    box-shadow: ${(props) => props.showBackground ? 'inset 5px 4px 19px rgba(213, 213, 213, 0.38)' : 'none'};
    border-radius: ${(props) => props.showBackground ? '14px' : 'none'};
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    align-items: center;
    color: #FFFFFF;
    justify-content: space-between;
`;

export const StyledSessionItemDetail = styled.div`
    display: flex;

    align-items: center;
`;

export const StyledSessionIcon = styled.div`
    width: 66px;
    height: 66px;
    background-image: url(${(props)=>props.imgURL});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 14px;
    margin-right: 16px;
`;
export const StyledSessionTitle = styled.div`
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;

    color: #FFFFFF;
`;
export const StyledSessionPlay = styled(IconButton)`
    color: #FFFFFF !important;
    background: #464646 !important;
    border-radius: 42px !important;
    height: 42px;
    width: 42px;

`;