import React, { useEffect, useState } from 'react'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import Avatar from 'react-avatar'
import { UserAuth } from 'src/context/AuthContext'
import { useNavigate, Link } from 'react-router-dom'
import { getCustomerDetails } from 'src/services/APIs/Customer/CustomerDetails'
import { getPersonalDetails } from 'src/services/APIs/Customer/PersonalDetails'

const AppHeaderDropdown = ({ userName }) => {
  const { logOut } = UserAuth()
  const navigate = useNavigate()
  const [details, setDetails] = useState()
  const [picture, setPicture] = useState()

  const handleLogout = async () => {
    try {
      await logOut()
      navigate('/login')
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message)
    }
  }

  const customerDetails = async () => {
    const response = await getCustomerDetails()
    console.log(response.data)
    setDetails(response.data)
    sessionStorage.setItem('companyName', response.data.companyName)
  }

  const personalDetails = async () => {
    const response = await getPersonalDetails()
    console.log(response.data)
    sessionStorage.setItem('Name', response.data.name)
    setPicture(response.data.profilePicId)

    // setDetails(response.data)
    // sessionStorage.setItem('companyName', response.data.companyName)
  }

  useEffect(() => {
    customerDetails()
    personalDetails()
  }, [])

  const companyName = sessionStorage.getItem('companyName')
  const name = sessionStorage.getItem('Name')
  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0 flex" caret={false}>
        <div className="flex flex-row gap-2">
          <Avatar
            name={name}
            src={`https://media.aumhum.xyz/images/prod/${picture}`}
            size="50"
            round={true}
          />
          <div>
            <h6 className="font-black text-gray-900 dark:text-white mt-1 mb-0 pb-0">{name}</h6>
            <p className="text-gray-400">{companyName}</p>
          </div>
        </div>
      </CDropdownToggle>
      <CDropdownMenu
        style={{ borderRadius: '20px' }}
        className="pt-0 p-2 dark:bg-bgDark   md:w-60 xl:w-64"
      >
        <h5 className="mx-auto text-center my-4 font-black text-textHeading dark:text-white">
          Profile
        </h5>
        <div className="w-full">
          <div className="w-full mx-auto py-1">
            <div className="w-max flex flex-row gap-2 items-center mx-auto">
              <Avatar
                name={name}
                src={`https://media.aumhum.xyz/images/prod/${picture}`}
                size="50"
                round={true}
              />
              <div>
                <h6 className="font-black text-gray-900 dark:text-white mt-1 mb-0 pb-0">{name}</h6>
                <p className="text-gray-400">{companyName}</p>
              </div>
            </div>
          </div>
        </div>
        <CDropdownDivider className="w-3/4 mx-auto" />
        <Link style={{ textDecoration: 'none' }} to={'/profile'}>
          <CDropdownItem className="rounded-md text-gray-900 dark:text-white">
            <div className="flex flex-row items-center gap-3 ml-2 my-2">
              <span>
                <img src="profile.png" />
              </span>
              <p className="text-lg my-auto">Profile</p>
            </div>
          </CDropdownItem>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={'/billingandplans'}>
          <CDropdownItem className="rounded-md text-gray-900 dark:text-white">
            <div className="flex flex-row items-center gap-3 ml-2 my-2">
              <span>
                <img src="payment.png" />
              </span>
              <p className="text-lg my-auto">Payments</p>
            </div>
          </CDropdownItem>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={'/profile'}>
          <CDropdownItem className="rounded-md text-gray-900 dark:text-white">
            <div className="flex flex-row items-center gap-3 ml-2 my-2">
              <span>
                <img src="settings.png" />
              </span>
              <p className="text-lg my-auto">Settings</p>
            </div>
          </CDropdownItem>
        </Link>
        <CDropdownItem
          style={{ cursor: 'pointer' }}
          onClick={handleLogout}
          className="rounded-md text-gray-900 dark:text-white"
        >
          <div className="flex flex-row items-center gap-3 ml-2 my-2">
            <span>
              <img src="logout.png" />
            </span>
            <p className="text-lg my-auto">Logout</p>
          </div>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
