import React from 'react';
import {StyledTileHeader, StyledTileContainer, StyledTileTitle, StyledTileMore} from './TileStyle';
import CardList from '../CardList';

const renderTitle = (tileData) => {
    return tileData?.title && <StyledTileTitle>{tileData?.title}</StyledTileTitle>
}

const renderMore = (tileData) => {
    return tileData?.showAll && <StyledTileMore>{'More >'}</StyledTileMore>
}

const Tile = (props) => {
    const {tileData} = props;
    return (
        <StyledTileContainer>
            <StyledTileHeader>
                {renderTitle(tileData)}
                {renderMore(tileData)}
            </StyledTileHeader>
            <CardList cardsData={tileData?.items}
                cardsType={tileData?.itemType}
                cardsCategory={tileData?.categoryType}
                cardsLayout={tileData?.layout}/>
        </StyledTileContainer>
    )
}

export default Tile;
