// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUFbTasBKIWJIrFUqRcqDd2bYHHqmZ2NQ",
  authDomain: "aumhum-e45f3.firebaseapp.com",
  databaseURL: "https://aumhum-e45f3.firebaseio.com",
  projectId: "aumhum-e45f3",
  storageBucket: "aumhum-e45f3.appspot.com",
  messagingSenderId: "75080491693",
  appId: "1:75080491693:web:61f2acf1ecbff5c51f9c24",
  measurementId: "G-5MNTMYMR46"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app
