import React, { useContext, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { mediaURL } from "src/consumer/constants";
import { StyledCarousel, StyledCoursePlay, StyledCarouselNextIcon, StyledCarouselPrevIcon, StyledHeroCard, StyledHeroCardContainer,StyledHeroCardContentContainer,StyledHeroCardDesc,StyledHeroCardTitle, StyledHeroCardTransition, StyledPlayButtonContainer } from "./HeroCardsStyle";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { getCourseCardDetails } from "src/consumer/helpers/courseCardHelper";
import CoursePlayer from "../CourseCards/CoursePlayer";
import { PlayerContext } from "src/consumer/context/PlayerContext";

const getCarouselIcon = (buttonType) => {
    return (
        <>
           {buttonType === 'prev'?<StyledCarouselPrevIcon/>:<StyledCarouselNextIcon/>}
           </>
    );
}
export const renderCardsList = (props, setSelectedCard, setShowPlayer, setPlayerData, playerData) => {
    const {cardsData} = props;
    return cardsData.map((card, index) => {
        const {courseTitle, courseCategory, courseDescription, coverImage} = getCourseCardDetails(card);
        return (
                coverImage && <Carousel.Item key={index} onClick={() => {
                    !playerData.showPIP && setSelectedCard(card);
                    setPlayerData(resetPlayerData(playerData));
                    setShowPlayer(true);
                    }}>
                    <StyledHeroCardContainer>
                        <StyledHeroCard imgURL={mediaURL + coverImage}>
                            <StyledHeroCardContentContainer>
                                <div>
                                    {courseCategory}
                                </div>
                                <StyledHeroCardTitle>
                                    {courseTitle}
                                </StyledHeroCardTitle>
                                <StyledHeroCardDesc>
                                    {courseDescription}
                                </StyledHeroCardDesc>
                                <StyledPlayButtonContainer>
                                    <StyledCoursePlay>
                                        <PlayCircleFilledWhiteIcon/>
                                    </StyledCoursePlay>
                                    <div>Play Now</div> 
                                </StyledPlayButtonContainer>
                            </StyledHeroCardContentContainer>
                        </StyledHeroCard>
                        <StyledHeroCardTransition />
                    </StyledHeroCardContainer>
                </Carousel.Item>
        );
    })
}

const resetPlayerData = (playerData) => {
    if(playerData?.showPIP) {
        return {
            showPIP: false,
            showDialog: true
        }
    }
    return playerData;
}

export const HeroCards = (props) => {
    const {playerData, setPlayerData} = useContext(PlayerContext);
    const [showPlayer, setShowPlayer] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});
    const toggleDrawer = (showPlayer) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        //log the timming when the player is closed
        setShowPlayer(showPlayer);
    };
    return (
        <>
            <div>
                {showPlayer && <CoursePlayer selectedCard={selectedCard} showPlayer={showPlayer} toggleDrawer={toggleDrawer}/>}
            </div>
            <StyledCarousel prevIcon={getCarouselIcon('prev')} nextIcon={getCarouselIcon('next')} interval={!showPlayer ? 5000 : null}>
                {renderCardsList(props, setSelectedCard, setShowPlayer, setPlayerData, playerData)}
            </StyledCarousel>
        </>
    );
}