import React from 'react';
import {Link} from 'react-router-dom';
import {TabAndTileCardsContainer, TabAndTileCard, TileTitleFirstPart, TileTitleSecondPart,
    TabAndTileVerticalCardsContainer, TabAndTileVerticalCard, TabAndTileVerticalDetails, TileTitleVerticalFirstPart,
    TileTitleVerticalSecondPart, TileVerticalDescription, TabAndTileVerticalImg} from './TabAndTileCardsStyles';
import {getTileCardDetails, getTitleParts} from '../../helpers/tileCardHelper';
import {mediaURL} from '../../constants';

export const renderCardsList = (props) => {
    const {cardsData} = props;
    // filtering curatable false until support is extended
    const supportedTabTileList = cardsData.filter(card => card?.tabAndTile?.curatable);
    return supportedTabTileList.map((card, index) => {
        const {tileTitle, tilePrimaryColor, tileSecondaryColor, tileId, policyId} = getTileCardDetails(card);
        // The second part which is the last word needs to have a different style
        const {titleFirstPart, titleSecondPart} = getTitleParts(tileTitle);
        return (
            <TabAndTileCard
                as={Link}
                to={`/${tileId}/${policyId}`}
                tilePrimaryColor={tilePrimaryColor}
                tileSecondaryColor={tileSecondaryColor}
                key={index}>
            <TileTitleFirstPart>{titleFirstPart}</TileTitleFirstPart>
            <TileTitleSecondPart>{titleSecondPart}</TileTitleSecondPart>
        </TabAndTileCard>)
    })
}

export const renderVerticalCardsList = (props) => {
    const {cardsData} = props;
    // filtering curatable false until support is extended
    const supportedTabTileList = cardsData.filter(card => card?.tabAndTile?.curatable);
    return supportedTabTileList.map((card, index) => {
        const {tileTitle, tilePrimaryColor, tileSecondaryColor, tileId, policyId, description, image} = getTileCardDetails(card);
        // The second part which is the last word needs to have a different style
        const {titleFirstPart, titleSecondPart} = getTitleParts(tileTitle);
        return (
            <TabAndTileVerticalCard
                as={Link}
                to={`/${tileId}/${policyId}`}
                tilePrimaryColor={tilePrimaryColor}
                tileSecondaryColor={tileSecondaryColor}
                key={index}>
                <TabAndTileVerticalDetails>
                    <TileTitleVerticalFirstPart>{titleFirstPart}</TileTitleVerticalFirstPart>
                    <TileTitleVerticalSecondPart>{titleSecondPart}</TileTitleVerticalSecondPart>
                    <TileVerticalDescription>{description}</TileVerticalDescription>
                </TabAndTileVerticalDetails>
                <TabAndTileVerticalImg imgURL={mediaURL + (image)}/>
        </TabAndTileVerticalCard>)
    })
}

const TabAndTileCards = (props) => {
    const {cardsLayout} = props;
    return (
        <>
        {cardsLayout === 'tab_tile_vertical' ? 
            (<TabAndTileVerticalCardsContainer>
                {renderVerticalCardsList(props)}
            </TabAndTileVerticalCardsContainer>) :
            (<TabAndTileCardsContainer>
                {renderCardsList(props)}
            </TabAndTileCardsContainer>)}
        </>
    )
}

export default TabAndTileCards;
