import React, { useState, useContext } from 'react';
import {CourseCardsContainer, CourseCard, CourseCategory, CourseTitle,
    CourseContent, CoursePlayButton, CourseDescription, CourseDetails, CourseHeader} from './CourseCardsStyle';
import {getCourseCardDetails, getCategoryListMap} from '../../helpers/courseCardHelper';
import {mediaURL, defaultBgImgId} from '../../constants';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CoursePlayer from './CoursePlayer';
import { SetupContext } from 'src/consumer/context/SetupContext';
import { PlayerContext } from 'src/consumer/context/PlayerContext';
import { AudioPip } from '../Player/AudioPip';

export const renderCardsList = (props, setSelectedCard,setShowPlayer, setupData, setPlayerData, playerData) => {
    const {cardsData} = props;
    return cardsData.map((card, index) => {
        const {courseTitle, courseIconImage, courseCategory, courseDescription, coverIconColor} = getCourseCardDetails(card, getCategoryListMap(setupData));
        return (
            <CourseCard  onClick={() => {
                !playerData.showPIP && setSelectedCard(card);
                setPlayerData(resetPlayerData(playerData));
                setShowPlayer(true);
                }} imgURL={mediaURL + (courseIconImage || defaultBgImgId)} key={index}>
                {courseCategory && <CourseCategory>
                    {courseCategory}
                </CourseCategory>}
                <CourseTitle>
                    {courseTitle}
                </CourseTitle>
                <CourseContent>
                    <CoursePlayButton>
                        <PlayCircleFilledWhiteIcon/>
                    </CoursePlayButton>
                    <CourseDetails bgColor={coverIconColor}>
                        <CourseHeader>
                            {courseTitle}
                        </CourseHeader>
                        <CourseDescription>
                            {courseDescription}
                        </CourseDescription>
                    </CourseDetails>
                </CourseContent>
            </CourseCard>
        )
    })
}

const resetPlayerData = (playerData) => {
    if(playerData?.showPIP) {
        return {
            showPIP: false,
            showDialog: true
        };
    }
    return playerData;
}

const CourseCards = (props) => {
    const {playerData, setPlayerData} = useContext(PlayerContext);
    const setupData = useContext(SetupContext);
    const [showPlayer, setShowPlayer] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});
    const toggleDrawer = (showPlayer) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        //log the timming when the player is closed
        setShowPlayer(showPlayer);
    };
    return (
        <>
            <div>
                {showPlayer && <CoursePlayer selectedCard={selectedCard} showPlayer={showPlayer} toggleDrawer={toggleDrawer}/>}
            </div>
            <CourseCardsContainer>
                {renderCardsList(props, setSelectedCard, setShowPlayer, setupData, setPlayerData, playerData)}
            </CourseCardsContainer>
        </>
    )
}

export default CourseCards;
