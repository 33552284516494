import styled from "styled-components";
import { Slider } from '@material-ui/core';

export const AudioPipContainer = styled.div`
    position: fixed;
    z-index: 1000;
    bottom: 0px;
    height: 90px;
    background: rgba(0,0,0,0.5);
    box-shadow: 0px -27px 60px rgba(0, 0, 0, 0.51);
    backdrop-filter: blur(8px);
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 120px;
    justify-content: end;
`;

export const StyledSliderContainer = styled.div`
    width: 40%;
    color: white;
    margin-right: 120px;
`;

export const PrettoSlider = styled(Slider)`
    height: 5px !important;
    & .MuiSlider-track {
      border: 'none';
      height: 6px;
      background-color: #706BCA;
    }
    & .MuiSlider-rail {
        height: 6px;
        background: #9A9A9A;
    }
    & .MuiSlider-thumb {
      height: 15px;
      width: 15px;
      background: #FFFFFF;
    },
`;

export const StyledPlayPauseButton = styled.img`
    height: 40px;
    width: 40px;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledForwardButton = styled.img`
    height: 25px;
    width: 25px;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledPlayControls = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 93px;
    gap: 30px;
`;

export const StyledHeaderButton = styled.img`
    z-index: 10000;
    height: 30px;
    width: 30px;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledSessionIcon = styled.div`
    width: 60px;
    height: 60px;
    background-image: url(${(props)=>props.imgURL});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-color: #FFFFFF;
    border-width: 2px;
    border-radius: 14px;
    margin-right: 16px;
`;
export const StyledSessionTitle = styled.div`
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
`;

export const StyledSessionSubTitle = styled.div`
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
`;

export const StyledSessionItemDetail = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: 60px;
    &:hover {
        cursor: pointer;
    }
`;