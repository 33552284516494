import React, {useContext, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../services/index'
import {getTilesData} from '../../helpers/tilesDataHelper';
import {StyledTilesPageContainer, StyledTilesPageHeader, StyledTilesPageTitle, StyledTilesList,
    StyledTitleSecondPart, TilesPageTrialBanner, TilesPageTrialBannerContainer} from './TilesPageStyle';
import {mediaURL, defaultBgImgId, supportedCategories, HERO_CARD} from '../../constants';
import Tile from '../Tile';
import Spinner from 'react-bootstrap/Spinner';
import { HeroCards } from '../HeroCards';
import {SetupContext} from '../../context/SetupContext';
import {getTabOrder} from '../../helpers/tabDataHelper';
import Prewall from '../PrewallAndPaywall/Prewall';
import {getTitleParts} from '../../helpers/tileCardHelper';

const renderTilesList = (tilesList) => {
    const supportedTilesList = tilesList.filter(tiles => supportedCategories.includes(tiles?.itemType) && tiles?.categoryType !== HERO_CARD);
    return supportedTilesList.map((tile, index) => <Tile key={index} tileData={tile}/>)
}

const TilesPage = (props) => {
    const {tileId, policyId} = useParams();
    const [isPrewallOpen, setIsPrewallOpen] = useState(false);
    const setupData = useContext(SetupContext);
    const defaultTab = getTabOrder(setupData)?.preselectedTab;
    const { loading, data, error } = useFetch('/secure/tile', `&tileType=${(tileId || defaultTab)}&policyId=${policyId || ''}`);
    if(loading)
        return <Spinner animation="border" variant="light"/>;
    const {preselectedImage, pageTitle, tilesList, userData} = getTilesData(data);
    const {titleFirstPart, titleSecondPart} = getTitleParts(pageTitle);
    const supportedHeroCard = tilesList[0]?.categoryType === HERO_CARD ? tilesList[0]?.items.filter(card => card.type === 'course') : [];
    const hasHeroCard = supportedHeroCard.length > 0;
    let trialBanner = "";
    const isFreeUser = userData?.subscriptionTypeId === "subscription_free";
    const isTrialSubscription = userData?.subscriptionPlanType === "freeTrial";
    const trialExpiryDays = (userData?.subscriptionEndDate - userData?.subscriptionStartDate) / (60*60*24);
    trialBanner = isFreeUser ? userData?.trialOffered ? "Trial Expired" : "" : isTrialSubscription ? `Trial expires in ${trialExpiryDays} days` : "";
    return (
        <StyledTilesPageContainer imgURL={mediaURL + (preselectedImage?.imageId || defaultBgImgId)} hasHeroCard={hasHeroCard}>
            {hasHeroCard ?
                <HeroCards cardsData={supportedHeroCard}/> :
                <>
                {
                    !(tileId && tileId !== "me") && trialBanner && trialBanner !== "" &&
                    <TilesPageTrialBannerContainer>
                        <TilesPageTrialBanner onClick={() => setIsPrewallOpen(true)}>
                            {trialBanner}
                        </TilesPageTrialBanner>
                    </TilesPageTrialBannerContainer>}
                {
                    <StyledTilesPageHeader>
                    {(tileId && tileId !== defaultTab) && 
                        <StyledTilesPageTitle>
                            <div>{titleFirstPart}</div>
                            <StyledTitleSecondPart>{titleSecondPart}</StyledTitleSecondPart>
                        </StyledTilesPageTitle>}
                </StyledTilesPageHeader>}
            </>}
            <StyledTilesList>{renderTilesList(tilesList)}</StyledTilesList>
            <Prewall isPrewallOpen={isPrewallOpen} handleOnBackClick={() => setIsPrewallOpen(false)}/>
        </StyledTilesPageContainer>
    )
}

export default TilesPage
