import React, {useState, useRef, useEffect, useContext} from 'react';
import { StyledDrawer, StyledPlayerContainer, StyledHeaderButton,
  StyledPlayer, StyledPlayerHeader, PlayListContainerPopup,
  CoursePlayListHeader, 
  MinimizeButton,
  OpenPlaylistButton} from './PlayerStyle';
import {mediaURL} from '../../constants';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ReactPlayer from 'react-player';
import { CourseDetails } from './CourseDetails';
import { CoursePlayList } from './CoursePlayList';
import PlayerControls from './PlayerControls';
import {getSessionProgressData} from '../../helpers/playerHelper';
import {putSessionProgress} from '../../services/playerService';
import DeviceDetector from "device-detector-js";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import coursePlayListIcon from 'src/assets/course_playlist_icon.png';
import closeDrawerIcon from 'src/assets/close_drawer.png';
import minimisePlayerIcon from 'src/assets/minimise_player.png';
import GlobalStyles from "@mui/material/GlobalStyles";
import { PlayerContext } from 'src/consumer/context/PlayerContext';
import { LeavingEarly } from './LeavingEarly';
import { AudioPip } from './AudioPip';

export default function Player(props) {
  const {playerData, setPlayerData} = useContext(PlayerContext);
  const token = sessionStorage.getItem('token');
  const [isCourseDetailOpen, setIsCourseDetailOpen] = useState(false);
  const [isCoursePlaylistOpen, setIsCoursePlaylistOpen] = useState(false); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const stepsArray = [];
  const {signedURL, loading, courseType, showPlayer, toggleDrawer, ongoingSession,
    setOngoingSession, sessionsList, sessionId, courseId } = props;
  const [play, setPlay] = useState({
    playing: !playerData.showDialog,
    played: 0
  });
  const filteredSignedURL = signedURL.filter(step => step !== null);
  filteredSignedURL.forEach((step, index) => {
    stepsArray.push({
      url: step,
      complete: false,
      duration: 0,
      mark: parseFloat(1/filteredSignedURL.length) * index * 100
    })
  })

  const isSingleSession = sessionsList.length === 1;
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const deviceDetials = deviceDetector.parse(userAgent);
  const clientSessionId = uuidv4();
  const clientCreatedTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
  //const canPlayPIP = ReactPlayer.canEnablePIP(signedURL)

  
  const divStyle = {
    backgroundImage: courseType === 'video' ? 'none' : 'url(' + mediaURL + sessionsList[ongoingSession]?.coverImage + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    marginTop: '-40px'
  };

  const playerRef = useRef(null);

  const handlePlayPause = () => {
    setPlay({...play, playing: !(play.playing)});
  }

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  }

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  }

  const handleProgress = (changedState) => {
    setPlay({...play, ...changedState});
  }

  const handleSeekCommitted = (e, newValue) => {
    const newCurrentStep = parseInt(newValue/(100/filteredSignedURL.length));
    if(newCurrentStep !== currentStep) {
      setCurrentStep(newCurrentStep);
      setPlay({...play, playing: true, played: 0});
      playerRef.current.seekTo(0);
    } 
    else {
      const newPlayedValue = (newValue - parseFloat(100/filteredSignedURL.length) * newCurrentStep) * filteredSignedURL.length;
      setPlay({...play, playing: true, played: parseFloat(newPlayedValue/100)});
      playerRef.current.seekTo(newPlayedValue/100);
    }
  }

  const handleSeek = (e, newValue) => {
    const newPlayedValue = (newValue - parseFloat(100/filteredSignedURL.length) * currentStep) * filteredSignedURL.length;
    setPlay({...play, playing: false, played: parseFloat(newPlayedValue/100)});
    playerRef.current.seekTo(newPlayedValue/100);
  }

  const sendSessionUpdates = (completed = false) => {
      const completedDuration = parseInt(playerRef?.current?.getCurrentTime() * 1000) || 0;
      const targetDuration = parseInt(playerRef?.current?.getDuration() * 1000) || sessionsList[ongoingSession]?.duration;
      const data = getSessionProgressData({courseId, sessionId, targetDuration, completedDuration, completed,
        deviceDetials, clientSessionId, clientCreatedTime, userAgent});
      putSessionProgress('https://live.aumhum.xyz/api/secure/online/courses/sessions?lat=17.9387192&lng=79.9061843&date=2022-02-19&zoneOffset=%2B05%3A30&minDuration=2&maxDuration=120&languageCode=en&appVersion=2.2.07&platform=ios&ef-app-language=en&countryCode=in&tileType=me&tilePolicyId=',
      data)
  }

  const onStepEnd = () => {
    if(currentStep === signedURL.length - 1) {
      clearInterval(sessionUdpatesID)
      sendSessionUpdates(true)
      setOngoingSession(ongoingSession + 1)
    } else {
      setCurrentStep(currentStep + 1);
      setPlay({...play, played: 0});
    }
  }

  const handlePlayListClicked = (event) => {
    setAnchorEl(event.currentTarget);
    setIsCoursePlaylistOpen(!isCourseDetailOpen);
  }

  const handleEnablePIP = () => {
    console.log("enable");
  }

  const handleDisablePIP = (event) => {
    console.log("disable");
    setPlayerData({...playerData, showPIP: false});
  }

  let sessionUdpatesID;
  useEffect(() => {
    sendSessionUpdates();
    sessionUdpatesID = setInterval(()=>{sendSessionUpdates()}, 60000);
    return () => clearTimeout(sessionUdpatesID);
  }, [])

  const handlePlayerClose = (event) => {
    if(play.played >= 1) toggleDrawer(false)(event);
    else {
      setPlay({...play, playing: false});
      if(playerData.showPIP)  setPlayerData({...playerData, showPIP: false, showDialog: true});
      else setPlayerData({...playerData, showDialog: true});
    }
  };

  const handleContinuePlaying = () => {
    setPlayerData({...playerData, showDialog: false});
    setPlay({...play, playing: true});
  }

  const handleExit = (event) => {
    setPlayerData({...playerData, showDialog: false});
    toggleDrawer(false)(event);
  }

  const handleDialogOpen = () => {
    setPlay({...play, playing: false});
  };
  const currentTime = playerRef.current? playerRef.current.getCurrentTime(): '00:00';
  const duration = playerRef.current? playerRef.current.getDuration(): '00:00';

  const drawerScroll = playerData.showPIP ? "auto !important": "hidden !important";

  return (
    <>
          <GlobalStyles styles={{ body: { overflow: drawerScroll} }}/>
          <StyledDrawer
            anchor={'top'}
            open={showPlayer}
            onClose={toggleDrawer(false)}
            showPIP={playerData.showPIP}
          >
              {/* courseType === "video" && */}
              {!loading && <StyledPlayerContainer>
                <StyledPlayerHeader>
                    <StyledHeaderButton onClick={handlePlayerClose} src={closeDrawerIcon}/>
                    {<MinimizeButton onClick={() => setPlayerData({...playerData, showPIP: true})} src={minimisePlayerIcon}/>}
                    {!isSingleSession && <OpenPlaylistButton onClick={handlePlayListClicked} src={coursePlayListIcon}/>}
                </StyledPlayerHeader>
                  <StyledPlayer>
                    {!loading && <ReactPlayer ref={playerRef}
                      config={{ file: { forceHLS: true, hlsOptions: { forceHLS: true, debug: false, xhrSetup: function (xhr, signedURL) { xhr.setRequestHeader("Authorization", `Bearer ${token}`); },}}}}
                      width='100%' height='100vh'  playing={play.playing} style={divStyle} onProgress={handleProgress} pip={playerData.showPIP} stopOnUnmount={!playerData.showPIP} onEnablePIP={handleEnablePIP} onDisablePIP={handleDisablePIP}
                      url={stepsArray[currentStep].url} onEnded={() => onStepEnd()}/>}
                  </StyledPlayer>
                  <PlayerControls handleFastForward={handleFastForward}  handlePlayPause={handlePlayPause} 
                    handleRewind={handleRewind} handleSeek={handleSeek} handleSeekCommitted={handleSeekCommitted} currentTime={currentTime}
                    stepsArray={stepsArray} currentStep={currentStep} duration={duration} play={play} setIsCourseDetailOpen={setIsCourseDetailOpen} />
                  {isCourseDetailOpen && <CourseDetails {...props} setIsOpen={setIsCourseDetailOpen} isSingleSession={isSingleSession}/>}
                  {!isSingleSession &&
                  isCoursePlaylistOpen &&
                  <PlayListContainerPopup
                    open={isCoursePlaylistOpen}
                    anchorEl={anchorEl}
                    onClose={() => setIsCoursePlaylistOpen(false)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <CoursePlayListHeader>Series</CoursePlayListHeader>
                    <CoursePlayList ongoingSession={ongoingSession} setOngoingSession={setOngoingSession} sessionsList={sessionsList} setIsOpen={setIsCoursePlaylistOpen} showBackground={false}/>
                  </PlayListContainerPopup>
                }
                {playerData.showDialog && <LeavingEarly handleContinuePlaying={handleContinuePlaying} handleExit={handleExit} handleDialogOpen={handleDialogOpen} />}
               </StyledPlayerContainer>}
          </StyledDrawer>
          {courseType === 'audio' && playerData.showPIP && <AudioPip handleDisablePIP={handleDisablePIP} handleFastForward={handleFastForward}  handlePlayPause={handlePlayPause} 
                    handleRewind={handleRewind} handleSeek={handleSeek} handleSeekCommitted={handleSeekCommitted} play={play} handlePlayerClose={handlePlayerClose} ongoingSession={ongoingSession} sessionsList={sessionsList} teacherTitle={props?.teacherTitle}/>}
    </>
  );
}
