import styled from "styled-components";
import IconButton from '@mui/material/IconButton';

export const CourseCardsContainer = styled.div`
    display: flex;
    padding: 90px 50px 50px 50px;
    width: 100%;
    overflow: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const CourseCategory = styled.div`
    padding: 7px;
    background: rgba(0, 0, 0, 0.38);
    backdrop-filter: blur(14px);
    border-radius: 28px;
    color: #FFFFFF;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    width: fit-content;
`;

export const CourseTitle = styled.div`
    padding: 7px;
    background: rgba(0, 0, 0, 0.38);
    backdrop-filter: blur(14px);
    border-radius: 28px;
    color: #FFFFFF;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    position: absolute;
    bottom: 12px;
    display: flex;
    justify-content: center;
    width: 287px;
`;

export const CourseContent = styled.div`
    bottom: 0px;
    position: absolute;
    visibility: hidden;
    width: 100%;
`;

export const CoursePlayButton = styled(IconButton)`
    .MuiSvgIcon-root{
        width: 60px;
        height: 62px;
        color: #FFFFFF;
    }
`;
export const CourseDetails = styled.div`
    // background-color: ${(props)=>props.bgColor};
    backdrop-filter: blur(58px);
    height: 150px;
    border-radius: 0px 0px 10px 10px;
    padding: 12px 14px;
`;
export const CourseHeader = styled.div`
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
`;
export const CourseDescription = styled.div`
    padding-top: 18px;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;
`;

export const CourseCard = styled.div`
    flex: 0 0 329px;
    height: 338px;
    background-image: url(${(props)=>props.imgURL});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 88px;
    border-radius: 10px;
    padding: 12px 21px;
    position: relative;
    &:hover {
        cursor: pointer;
        padding: 12px 0px 0px 0px;
        transition: padding 0s ease-in-out .5s, transform .5s ease-in-out;
        transform: scale(1.1);
        ${CourseTitle} {
            visibility: hidden;
            transition: visibility 0s ease-in-out .5s;
        }
        ${CourseContent} {
            visibility: visible;
            transition: visibility 0s ease-in-out .5s;
        }
        ${CourseCategory} {
            margin-left: 21px;
            transition: margin-left 0s ease-in-out .5s;
        }
    }
`;