import React from 'react';
import { StyledDrawer, StyledDetailContainer, StyledBackButton, StyledDetailContent,
    StyledCourseDetail, StyledCourseTitle, StyledCourseTeacher, StyledCourseDesc,
    StyledCoursePlaylist, StyledPlaylistTitle, StyledSessionListContainer, StyledSessionListItem,
    StyledSessionIcon, StyledSessionTitle, StyledSessionPlay, StyledSessionItemDetail } from './CourseDetailsStyle';
import {mediaURL} from '../../constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { CoursePlayList } from './CoursePlayList';

export function CourseDetails(props) {
    const {teacherTitle, courseTitle, courseLongDescription, showPlayer, ongoingSession,
        setOngoingSession, sessionsList, setIsOpen, isSingleSession} = props;

    return (
            <StyledDrawer
                anchor={'bottom'}
                open={showPlayer}
                onClose={() => setIsOpen(false)}
            >
                <StyledDetailContainer>
                    <StyledBackButton onClick={(() => setIsOpen(false))}>
                        <KeyboardArrowDownIcon fontSize='large'/>
                    </StyledBackButton>
                    <StyledDetailContent>
                        <StyledCourseDetail>
                            <StyledCourseTitle>
                                {courseTitle}
                            </StyledCourseTitle>
                            <StyledCourseTeacher>
                                {`By ${teacherTitle}`}
                            </StyledCourseTeacher>
                            <StyledCourseDesc>
                                {courseLongDescription}
                            </StyledCourseDesc>
                        </StyledCourseDetail>
                        {!isSingleSession && <StyledCoursePlaylist>
                            <StyledPlaylistTitle>
                                {'Playlist'}
                            </StyledPlaylistTitle>
                            <CoursePlayList ongoingSession={ongoingSession} setOngoingSession={setOngoingSession} sessionsList={sessionsList} setIsOpen={setIsOpen} showBackground={true}/>
                        </StyledCoursePlaylist>}
                    </StyledDetailContent>
                </StyledDetailContainer>
            </StyledDrawer>
    );
}
