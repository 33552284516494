import React from 'react';
import { StyledSessionListContainer, StyledSessionListItem,
    StyledSessionIcon, StyledSessionTitle, StyledSessionPlay, StyledSessionItemDetail } from './CourseDetailsStyle';
import {mediaURL} from '../../constants';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import sessionPlaying from 'src/assets/session_playing.png'

export function CoursePlayList(props) {
    const {ongoingSession, setOngoingSession, sessionsList, setIsOpen, showBackground} = props;

    return (
        <StyledSessionListContainer>
            {sessionsList.map((session, index) => {
                return (
                    <StyledSessionListItem showBackground={showBackground} key={index}>
                        <StyledSessionItemDetail>
                            <StyledSessionIcon imgURL={mediaURL + session.coverIcon}></StyledSessionIcon>
                            <StyledSessionTitle>{session?.title}</StyledSessionTitle>
                        </StyledSessionItemDetail>
                        <StyledSessionPlay onClick={() => {
                            setIsOpen(false);
                            setOngoingSession(index)}}>
                            {(ongoingSession === index) ? <img src={sessionPlaying}/>: <PlayArrowRoundedIcon/>}
                        </StyledSessionPlay> 
                    </StyledSessionListItem>
                )
            })}
        </StyledSessionListContainer>);
}
