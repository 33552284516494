import React from 'react';
import {CourseCardsContainer, CategoryCard, CategoryImage, CategoryTitle} from './CategoryCardsStyle';
import {getCategoryCardDetails} from '../../helpers/categoryCardHelper';
import {mediaURL, defaultColor} from '../../constants';

export const renderCardsList = (props) => {
    const {cardsData} = props;
    return cardsData.map((card, index) => {
        const {categoryTitle, categoryImage, bgColor} = getCategoryCardDetails(card);
        return (
            <CategoryCard bgColor={bgColor || defaultColor} key={index}>
                <CategoryTitle>
                    {categoryTitle}
                </CategoryTitle>
                {categoryImage && <CategoryImage imgURL={mediaURL + (categoryImage)}/>}
            </CategoryCard>
        )
    })
}

const CategoryCards = (props) => {
    return (
        <CourseCardsContainer>
            {renderCardsList(props)}
        </CourseCardsContainer>
    )
}

export default CategoryCards;
