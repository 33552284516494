import React, { useEffect } from "react";
import { LeavingEarlyContainer, LeavingEarlyBox, LeavingEarlyHeader, LeavingEarlyTitle, LeavingEarlySubTitle, ButtonContainer, LeavingEarlyActions, ContinueButtonContainer, ExitButtonContainer, LeavingEarlyActionContainer, CloseIcon } from "./LeavingEarlyStyle";
import closeDrawerIcon from 'src/assets/close_drawer.png';

export const LeavingEarly = (props) => {
    const {handleContinuePlaying, handleExit, handleDialogOpen } = props;

    useEffect(() => {
        handleDialogOpen();
    },[]);
    return (
        <LeavingEarlyContainer>
            <LeavingEarlyBox>
                <LeavingEarlyHeader>
                    <LeavingEarlyTitle>
                        Leaving Early?
                        <CloseIcon onClick={handleContinuePlaying} src={closeDrawerIcon}/>
                    </LeavingEarlyTitle>
                    <LeavingEarlySubTitle>Please tell us why</LeavingEarlySubTitle>    
                </LeavingEarlyHeader>
                <LeavingEarlyActionContainer>
                    <LeavingEarlyActions>
                        <ContinueButtonContainer  onClick={handleContinuePlaying}>Continue Playing?</ContinueButtonContainer>
                        <ExitButtonContainer onClick={handleExit}>Don’t like the audio quality</ExitButtonContainer>
                    </LeavingEarlyActions>
                    <LeavingEarlyActions>
                        <ExitButtonContainer  onClick={handleExit}>Difficult to understand</ExitButtonContainer>
                        <ExitButtonContainer onClick={handleExit}>Facing technical problems</ExitButtonContainer>
                    </LeavingEarlyActions>
                    <LeavingEarlyActions>
                        <ExitButtonContainer  onClick={handleExit}>Not of my taste</ExitButtonContainer>
                        <ExitButtonContainer onClick={handleExit}>Video quality</ExitButtonContainer>
                    </LeavingEarlyActions>
                    <LeavingEarlyActions>
                        <ExitButtonContainer  onClick={handleExit}>Do it later</ExitButtonContainer>
                        <ExitButtonContainer onClick={handleExit}>Do it later</ExitButtonContainer>
                    </LeavingEarlyActions>
                </LeavingEarlyActionContainer>
            </LeavingEarlyBox>
        </LeavingEarlyContainer>
    );
}