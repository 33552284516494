import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import img from '../../../assets/prewall.png';

export const StyledPrewallDrawer = styled(Drawer)`
    display: flex;
    justify-content: center;
    backdrop-filter: blur(15px);
    .MuiPaper-root{
        height: 100vh;
        width: 100%;
        left: auto;
        right: auto;
        background: #000000;
        border: 1px solid #FFFFFF;
        border-radius: 40px 40px 0px 0px;
        padding: 45px 15px 0px 55px;
        overflow: hidden;
    }
`;


export const StyledPrewallHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 43px;
`;

export const StyledBackButton = styled(IconButton)`
    height: 48px;
    width: 40px;
    color: #FFFFFF !important;
`;

export const StyledPrewallHeaderContent = styled.div`
    padding-left: 45px;    
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
`;

export const StyledPrewallSave = styled.button`
    width: 180px;
    height: 52px;
    background: #323232;
    border: 2px solid #939393;
    box-shadow: 7px 4px 31px rgba(0, 0, 0, 0.58);
    border-radius: 36px;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;

    color: #FFFFFF;
`;

export const StyledPrewallContent = styled.div`
    height: 100vh;
    widht: 100%;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;