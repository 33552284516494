import styled from "styled-components";

export const TeacherCardsContainer = styled.div`
    display: flex;
    padding: 90px 50px 50px 50px;
    width:100%;
    overflow: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const TeacherCard = styled.div`
    flex: 0 0 257px;
    height: 500px;
    margin-right: 66px;
    position: relative;
`;

export const TeacherImage = styled.div`
    height: 360px;
    background-image: url(${(props)=>props.imgURL});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 128.5px;
    filter: drop-shadow(0px 4px 33px rgba(0, 0, 0, 0.7));
`;

export const TeacherTitle = styled.div`
    padding-top: 72px;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
`;