export const getTileCardDetails = (cardData) => {
    const tileTitle = cardData?.tabAndTile?.tileTitle || cardData?.tabAndTile?.title || '';
    const tilePrimaryColor = cardData?.tabAndTile?.defaultPrimaryThemeColor;
    const tileSecondaryColor = cardData?.tabAndTile?.defaultSecondaryThemeColor;
    const tileId = cardData?.tabAndTile?.id;
    const policyId = cardData?.tabAndTile?.policyId;
    const description = cardData?.tabAndTile?.description;
    const image = cardData?.tabAndTile?.image;
    return {tileTitle, tilePrimaryColor, tileSecondaryColor, tileId, policyId, description, image};
}

export const getTitleParts = (title) => {
    const titleWordsList = title.trim().split(" ");
    let titleFirstPart = "", titleSecondPart = "";
    titleSecondPart = titleWordsList[titleWordsList.length - 1];
    titleWordsList.pop();
    titleFirstPart = titleWordsList.join(" ");
    return {titleFirstPart, titleSecondPart};
}