import { createContext, useContext, useEffect, useState, React } from 'react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
} from 'firebase/auth'
import { auth } from '../firebase'
import PropTypes from 'prop-types'

export const UserContext = createContext()

export function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  // const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)

  function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }
  function logOut() {
    sessionStorage.clear()
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider()
    return signInWithRedirect(auth, googleAuthProvider)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setCurrentUser(currentUser)
      setLoading(false)
    })

    return unsubscribe
  }, [])
  return (
    <UserContext.Provider value={{ currentUser, signIn, logOut, googleSignIn }}>
      {children}
    </UserContext.Provider>
  )
}

export function UserAuth() {
  return useContext(UserContext)
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
