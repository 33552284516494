import React from 'react';
import {TeacherCardsContainer, TeacherCard, TeacherImage, TeacherTitle} from './TeacherCardsStyle';
import {getTeacherCardDetails} from '../../helpers/teacherCardHelper';
import {mediaURL, defaultColor} from '../../constants';

export const renderCardsList = (props) => {
    const {cardsData} = props;
    return cardsData.map((card, index) => {
        const {teacherTitle, teacherImage} = getTeacherCardDetails(card);
        return (
            <TeacherCard bgColor={defaultColor} key={index}>
                {teacherImage && <TeacherImage imgURL={mediaURL + (teacherImage)}/>}
                <TeacherTitle>
                    {teacherTitle}
                </TeacherTitle>
            </TeacherCard>
        )
    })
}

const TeacherCards = (props) => {
    return (
        <TeacherCardsContainer>
            {renderCardsList(props)}
        </TeacherCardsContainer>
    )
}

export default TeacherCards;
