import React from 'react';
import {StyledPrewallDrawer, StyledPrewallHeader, StyledBackButton, StyledPrewallHeaderContent, StyledPrewallSave, StyledPrewallContent} from './PrewallStyles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const Prewall = (props) => {
    const {isPrewallOpen, handleOnBackClick} = props;
    return (
        <StyledPrewallDrawer anchor='bottom' open={isPrewallOpen}>
            <StyledPrewallHeader>
                <StyledBackButton>
                    <ArrowBackIosNewIcon onClick={handleOnBackClick}/>
                </StyledBackButton>
                <StyledPrewallHeaderContent>
                    {""}
                </StyledPrewallHeaderContent>
                <StyledPrewallSave>
                    Save
                </StyledPrewallSave>
            </StyledPrewallHeader>
            <StyledPrewallContent/>
        </StyledPrewallDrawer>
    )
}

export default Prewall;
